@if (item) {
<div class="single-item"
  [ngClass]="view">
  <h6 class="item-title">
    <span class="calendar"
      [ngClass]="{'movable': item.movable}"
      [title]="(item.movable ? 'Покретни празник' : 'Непокретни празник') | translit">{{item.saintDay}}. {{item.saintMonth}}.</span>
    <span class="item-name">{{ item.name | translit}}</span>
    @if (authService.loginAdmin$ | async) {
    <mat-icon class="edit-button"
      (click)="onEdit()">
      construction
    </mat-icon>
    }
  </h6>
  <div class="content-holder">
    <div class="item-image-div">
      <img class="item-image"
        loading="lazy"
        [src]="item.imagePath"
        [alt]="item.name | translit">
    </div>
    @if (view === 'full') {
    <div class="item-data desc">
      {{ item.description | translit}}
    </div>
    }
  </div>
</div>
}