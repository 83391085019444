import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslitPipe } from '@app/pipes/translit.pipe';
import { AuthService } from '@services/auth.service';
import { Holiday } from '@services/saints.service';

@Component({
  selector: 'app-saint-card',
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslitPipe],
  templateUrl: './saint-card.component.html',
  styleUrls: ['./saint-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaintCardComponent {
  @Input({ required: true }) item!: Holiday;
  @Input() view = 'full';

  private router = inject(Router);

  public authService = inject(AuthService);

  onEdit() {
    const id = this.item.id;
    this.router.navigateByUrl(`admin/panel/saint-form/${id}`);
  }
}
